import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pannellum } from "pannellum-react";
import myImage from "../../img/primerPiso/1-1.jpg";
import './Home.css';
import 'font-awesome/css/font-awesome.min.css';



const Home = () => {

    const history = useHistory();
    const [load, setOnLoad] = useState(false);
    const handleClick = () => {
        history.push("/homeVideo");
    }

    
    

    useEffect (() => {
      if (load) {
          let links = document.getElementsByTagName("a");
          for(let link of links){
              link.target = "_self";
              
              
              
          }
      }
      let infos = document.getElementsByClassName("pnlm-info");
      for(let info of infos){
        info.classList.add("vibrate-1");
        console.log(info);
      }
      
    })

 return(
 <div id="panorama ">
   
    <Pannellum
        id="home"
        width="100%"
        height="900px"
        image={myImage}
        pitch={-5}
        yaw={180}
        hfov={110}
        autoLoad
        onLoad={() => {
            setOnLoad(true);
        }}
        handleClick={() => handleClick()}
    >

        <Pannellum.Hotspot
          type="info"
          pitch={-15}
          yaw={-230}
          text="Administración"
          URL=""
        />

        <Pannellum.Hotspot
          type="custom"
          pitch={0}
          yaw={-167}
          handleClick={() => handleClick()}        
        />

        <Pannellum.Hotspot
          type="info"
          pitch={-10}
          yaw={-145}
          text="Ingenieria"
          URL="http://360.lantiasas.com/engineering"
        />

        <Pannellum.Hotspot
          type="info"
          pitch={-10}
          yaw={-115}
          text="Consultoria"
          URL="http://360.lantiasas.com/consulting"
        />
        
        <Pannellum.Hotspot
          type="info"
          pitch={-15}
          yaw={-95}
          text="Segundo piso"
          URL="http://360.lantiasas.com/secondFloor"
        />
        
    </Pannellum>
  
  </div>
)};
 
export default Home;
