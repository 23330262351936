import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Engineering from "./components/Engineering/Engineering";
import Consulting from "./components/Consulting/Consulting";
import HomeVideo from "./components/Home/HomeVideo";
import SecondFloor from "./components/SecondFloor/SecondFloor";
import SecondFloor22 from "./components/SecondFloor/SecondFloor22";
import SecondFloor23 from "./components/SecondFloor/SecondFloor23";
import SecondFloor25 from "./components/SecondFloor/SecondFloor25";
import SecondFloor28 from "./components/SecondFloor/SecondFloor28";
import ThirdFloor from "./components/Thirdfloor/ThirdFloor";
import ThirdFloor2 from "./components/Thirdfloor/ThirdFloor2";
import ThirdFloor3 from "./components/Thirdfloor/ThirdFloor3";
import Vigilance from "./components/Vigilance/Vigilance";
import Balcony1 from "./components/SecondFloor/Balcony";
import Balcony2 from "./components/SecondFloor/Balcony2";
import EngineeringVideo from "./components/Engineering/EngineeringVideo";
import ConsultingVideo from "./components/Consulting/ConsultingVideo";
import CoordinationVideo from "./components/Coordination/CoordinationVideo";
import DesignVideo from "./components/Consulting/DesignVideo";
import VigilanceVideo from "./components/Vigilance/VigilanceVideo";
import EngineeringMiddle from "./components/Engineering/EngineeringMiddle";
import ConsultingMiddle from "./components/Consulting/ConsultingMiddle";
import EngineeringBack from "./components/Engineering/EngineeringBack";
import ConsultingBack from "./components/Consulting/ConsultingBack";
import ConsultingBackEnd from "./components/Consulting/ConsultingBackEnd";
import Descanso from "./components/Consulting/Descanso";
import Coordination from "./components/Coordination/Coordination";

import "./App.css";

function App() {

  
  return (
    <div>
      <BrowserRouter>
      <Route exact path="/" component={Home} />
        <Route exact path="/homeVideo" component={HomeVideo} />
        <Route exact path="/engineering" component={Engineering} />
        <Route exact path="/consulting" component={Consulting} />
        <Route exact path="/descanso" component={Descanso} />
        <Route exact path="/coordination" component={Coordination} />
        <Route exact path="/secondFloor" component={SecondFloor} />
        <Route exact path="/secondFloor22" component={SecondFloor22} />
        <Route exact path="/secondFloor23" component={SecondFloor23} />
        <Route exact path="/secondFloor25" component={SecondFloor25} />
        <Route exact path="/secondFloor28" component={SecondFloor28} />
        <Route exact path="/thirdFloor" component={ThirdFloor} />
        <Route exact path="/thirdFloor2" component={ThirdFloor2} />
        <Route exact path="/thirdFloor3" component={ThirdFloor3} />
        <Route exact path="/vigilance" component={Vigilance} />
        <Route exact path="/balcony1" component={Balcony1} />
        <Route exact path="/balcony2" component={Balcony2} />
        <Route exact path="/engineeringMiddle" component={EngineeringMiddle} />
        <Route exact path="/consultingMiddle" component={ConsultingMiddle} />
        <Route exact path="/engineeringBack" component={EngineeringBack} />
        <Route exact path="/consultingBack" component={ConsultingBack} />
        <Route exact path="/consultingBackEnd" component={ConsultingBackEnd} />
        <Route exact path="/engineeringVideo" component={EngineeringVideo} />
        <Route exact path="/consultingVideo" component={ConsultingVideo} />
        <Route exact path="/designVideo" component={DesignVideo} />
        <Route exact path="/coordinationVideo" component={CoordinationVideo} />
        <Route exact path="/vigilanceVideo" component={VigilanceVideo} />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
