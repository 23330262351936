import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import YT from 'react-youtube'


const ConsultingVideo = () => {

    const Consulting = useRef()
        const history = useHistory();
        const returnHome = () => {   
        history.push("/consultingBack");
        }

        const opts = {
            width: "100%",
            height: "900px",
            playerVars:{
            autoplay: 1,
             } 
        }

    return (
        <> 
            <button onClick={returnHome} style={{margin:"20px", position:"absolute"}}>Consultoría</button>
            <YT 
                videoId="Fg-yJTO2o04"
                opts={opts}
                onEnd={returnHome}
            />

        </>
    )
}

export default ConsultingVideo;