import React, {useRef} from 'react';
import { useHistory } from 'react-router-dom';
import YT from 'react-youtube'

const VigilanceVideo = () => {

    const home = useRef()
    const history = useHistory();
    const returnHome = () => {   
        history.push("/vigilance");
    }

    const opts = {
        width: "100%",
        height: "900px",
        playerVars:{
            autoplay: 1,
        } 
    }

    return (
    <> 
        <button onClick={returnHome} style={{margin:"20px", position:"absolute"}}>Vigilancia</button>
        <YT 
            videoId="CLZVy9n-Pp0"
            opts={opts}
            onEnd={returnHome}
        />

    </>
    )
}

export default VigilanceVideo;