import React, {useRef} from 'react';
import { useHistory } from 'react-router-dom';
import YT from 'react-youtube'

const HomeVideo = () => {

    const home = useRef()
    const history = useHistory();
    const returnHome = () => {   
        history.push("/");
    }

    const opts = {
        width: "100%",
        height: "900px",
        playerVars:{
            autoplay: 1,
        } 
    }

    return (
    <> 
        <button onClick={returnHome} style={{margin:"20px", position:"absolute"}}>Administración</button>
        <YT 
            videoId="bA8M2KROdxM"
            opts={opts}
            onEnd={returnHome}
        />

    </>
    )
}

export default HomeVideo;