import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pannellum } from "pannellum-react";
import myImage4 from "../../img/primerPiso/1-4.jpg";

const EngineeringMiddle = () => {

    const history = useHistory();
    const [load, setOnLoad] = useState(false);
    const handleClick = () => {
        history.push("/engineeringVideo");
    }

    useEffect (() => {
        if (load) {
            let links = document.getElementsByTagName("a");
            for(let link of links){
                link.target = "_self";
            }
        }
    })

 return(
 <div id="panorama">
    <Pannellum
        id="home"
        width="100%"
        height="900px"
        image={myImage4}
        pitch={-15}
        yaw={-15}
        hfov={110}
        autoLoad
        onLoad={() => {
            setOnLoad(true);
        }}
        handleClick={() => handleClick()}
    >
      <Pannellum.Hotspot
        type="info"
        pitch={-5}
        yaw={30}
        text="Administración"
        URL="http://360.lantiasas.com"
      />
      <Pannellum.Hotspot
        type="info"
        pitch={-5}
        yaw={-30}
        text="Ingenieria"
        URL="http://360.lantiasas.com/engineeringMiddle"
      />
      <Pannellum.Hotspot
        type="custom"
        pitch={-5}
        yaw={-40}
        handleClick={() => handleClick()}     
      />
      <Pannellum.Hotspot
        type="info"
        pitch={-5}
        yaw={0}
        text="Ingenieria"
        URL="http://360.lantiasas.com/engineering"
      />
  </Pannellum>
    </div>
)};

export default EngineeringMiddle;

