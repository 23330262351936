import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pannellum } from "pannellum-react";
import myImage33 from "../../img/tercerPiso/3-3.jpg";
import 'font-awesome/css/font-awesome.min.css';



const ThirdFloor3 = () => {

    const history = useHistory();
    const [load, setOnLoad] = useState(false);
    const handleClick = () => {
        history.push("/homeVideo");
    }

    
    

    useEffect (() => {
      if (load) {
          let links = document.getElementsByTagName("a");
          for(let link of links){
              link.target = "_self";
              
              
              
          }
      }
      let infos = document.getElementsByClassName("pnlm-info");
      for(let info of infos){
        info.classList.add("vibrate-1");
        console.log(info);
      }
      
    })

 return(
 <div id="panorama ">
   
    <Pannellum
        id="home"
        width="100%"
        height="900px"
        image={myImage33}
        pitch={10}
        yaw={30}
        hfov={110}
        autoLoad
        onLoad={() => {
            setOnLoad(true);
        }}
        handleClick={() => handleClick()}
    >
        
        <Pannellum.Hotspot
          type="info"
          pitch={-5}
          yaw={-80}
          text="Segundo Piso"
          URL="http://360.lantiasas.com/secondFloor25"
        />


        <Pannellum.Hotspot
          type="info"
          pitch={-10}
          yaw={-100}
          text="Zona Picnic"
          URL="http://360.lantiasas.com/thirdFloor2"
        />
        
        
    </Pannellum>
  
  </div>
)};
 
export default ThirdFloor3;
