import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pannellum } from "pannellum-react";
import myImage12 from "../../img/primerPiso/12.jpg";

const ConsultingMiddle = () => {

    const history = useHistory();
    const [load, setOnLoad] = useState(false);
    const handleClick = () => {
        history.push("/consultingVideo");
    }

    useEffect (() => {
        if (load) {
            let links = document.getElementsByTagName("a");
            for(let link of links){
                link.target = "_self";
            }
        }
    })

 return(
 <div id="panorama">
    <Pannellum
        id="home"
        width="100%"
        height="900px"
        image={myImage12}
        pitch={-10}
        yaw={20}
        hfov={110}
        autoLoad
        onLoad={() => {
            setOnLoad(true);
        }}
        handleClick={() => handleClick()}
    >
      
      <Pannellum.Hotspot
        type="info"
        pitch={-10}
        yaw={-5}
        text="Comunicación y Formación"
        URL="http://360.lantiasas.com/consulting"
      />
      <Pannellum.Hotspot
        type="info"
        pitch={-10}
        yaw={180}
        text="Pasillo"
        URL="http://360.lantiasas.com/consulting"
      />
      <Pannellum.Hotspot
        type="custom"
        pitch={-5}
        yaw={-30}
        handleClick={() => handleClick()}
       
      />

      
  </Pannellum>
    </div>
)};

export default ConsultingMiddle;

