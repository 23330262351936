import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pannellum } from "pannellum-react";
import myImage6 from "../../img/primerPiso/1-6.jpg";

const Consulting = () => {

    const history = useHistory();
    const [load, setOnLoad] = useState(false);
    const handleClick = () => {
        history.push("/consulting");
    }

    useEffect (() => {
        if (load) {
            let links = document.getElementsByTagName("a");
            for(let link of links){
                link.target = "_self";
            }
        }
    })

 return(
 <div id="panorama">
    <Pannellum
        id="home"
        width="100%"
        height="900px"
        image={myImage6}
        pitch={-10}
        yaw={20}
        hfov={130}
        autoLoad
        onLoad={() => {
            setOnLoad(true);
        }}
        handleClick={() => handleClick()}
    >
      <Pannellum.Hotspot
        type="info"
        pitch={-5}
        yaw={-350}
        text="Pasillo"
        URL="http://360.lantiasas.com/consulting"
      />

      {/* <Pannellum.Hotspot
        type="custom"
        pitch={-5}
        yaw={-310}
        handleClick={() => handleClick()}        
      /> */}
  </Pannellum>
    </div>
)};

export default Consulting;

