import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pannellum } from "pannellum-react";
import myImage3 from "../../img/primerPiso/1-3.jpg";

const EngineeringMiddle = () => {

    const history = useHistory();
    const [load, setOnLoad] = useState(false);
    const handleClick = () => {
        history.push("/engineeringVideo");
    }

    useEffect (() => {
        if (load) {
            let links = document.getElementsByTagName("a");
            for(let link of links){
                link.target = "_self";
            }
        }
    })

 return(
 <div id="panorama">
    <Pannellum
        id="home"
        width="100%"
        height="900px"
        image={myImage3}
        pitch={-10}
        yaw={180}
        hfov={110}
        autoLoad
        onLoad={() => {
            setOnLoad(true);
        }}
        handleClick={() => handleClick()}
    >
      <Pannellum.Hotspot
        type="info"
        pitch={-10}
        yaw={-140}
        text="Administración"
        URL="http://360.lantiasas.com"
      />
      <Pannellum.Hotspot
        type="info"
        pitch={-10}
        yaw={-30}
        text="Administración"
        URL="http://360.lantiasas.com/engineeringBack"
      />

      <Pannellum.Hotspot
        type="custom"
        pitch={0}
        yaw={-167}
        text="hola"
        handleClick={() => handleClick()}        
      />
  </Pannellum>
    </div>
)};

export default EngineeringMiddle;

